import React from "react";
import css from "./Alert.module.css";
import { IoIosCloseCircle } from "react-icons/io";

interface iAlert {
  type: "error" | "warning" | "info" | "success";
  message: string;
  banner?: boolean;
}

const Alert = ({ type, message, banner }: iAlert) => {
  return (
    <div className={[css.alert, css[type], banner ? css.banner : ""].join(" ")}>
      <IoIosCloseCircle className="icon" /> <span className={css.message}>{message}</span>
    </div>
  );
};

export default Alert;
