import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { sanityClient } from "./lib";

import Page from "./components/Page";
import Alert from "./components/Alert";
import Loading from "./components/Loading";
import { FaInfoCircle } from "react-icons/fa";
import BlockContent from "@sanity/block-content-to-react";

import { Page as iPage } from "./models";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  const [pages, setPages] = useState<iPage[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [notice, setNotice] = useState<any>(null);

  async function getGlobalNotice() {
    try {
      const noticeFetch = await sanityClient.fetch(`*[_type == "notice" && show == true][0]`);

      setNotice(noticeFetch);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function getPages() {
    try {
      const pagesFetch = await sanityClient.fetch(`*[_type == "page"] {_id, path}`);

      setPages(pagesFetch);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPages();
    getGlobalNotice();
  }, []);

  if (loading) {
    return (
      <div className="App">
        <div className="overlay">
          <Loading />
        </div>
      </div>
    );
  }

  if (!pages) {
    return (
      <div className="overlay">
        <Alert banner type="error" message="Connection error: could not load page data." />
      </div>
    );
  }

  if (pages.length === 0) {
    return (
      <div className="overlay">
        <Alert banner type="warning" message="No pages to display" />;
      </div>
    );
  }

  const GlobalNotice = () => {
    const serializers = {
      marks: {
        color: (props: any) => {
          const { hex } = props.mark;
          return <span style={{ color: hex }}>{props.children}</span>;
        },
        link: (props: any) => {
          const { link, newtab } = props.mark;
          return <a target={newtab ? "_blank" : "_self"} href={link}>{props.children}</a>;
        }
      }
    };

    if (notice) {
      return (
        <div className="globalNotice">
          <BlockContent blocks={notice.content} serializers={serializers} />
        </div>
      );
    }

    return null;
  }

  console.log(pages);

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Sidebar />

        <Switch>
          {/* Render all pages dynamically */}
          {pages.map((page: iPage) => {
            return (
              <Route exact key={page._id} path={page.path}>
                <GlobalNotice />
                <Page path={page.path} />
              </Route>
            );
          })}

          {/* Create routes for staff members */}
          <Route exact path="/people/:slug" children={<Page template="people" path="/people" />} />

          {/* 404 catch all */}
          <Route path="*">
            <Page path="*" template="static">
              <h1>
                <FaInfoCircle /> 404: Page not found
              </h1>
              <p>Please check the URL and try again.</p>
            </Page>
          </Route>
        </Switch>

        <Footer />
      </Router>
    </div>
  );
};

export default App;
