import React, { useEffect, useState, Fragment } from "react";
import BlockContent from "@sanity/block-content-to-react";
import { sanityClient } from "../../../../lib";
import { Conference } from "../../../../models/Conference";
import Skeleton from "../../../Skeleton";
import Alert from "../../../Alert";
import { GoCalendar, GoLocation } from "react-icons/go";
import { FaChevronRight } from "react-icons/fa";
import css from "./Conferences.module.css";
import { format } from "date-fns";

interface iConferences {
  data: any;
}

export const Conferences = ({ data }: iConferences) => {
  const { title, content } = data;

  const [confs, setConfs] = useState<Conference[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  async function getConferences() {
    try {
      const fetch = await sanityClient.fetch(`*[_type == "conference"]`);
      setConfs(fetch);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getConferences();
  }, []);

  interface iButton {
    text: string;
    link: string;
  }

  const Button = ({ text, link }: iButton) => {
    return (
      <a href={link} className={css.button}>
        {text} &nbsp;
        <FaChevronRight />
      </a>
    );
  };

  return (
    <Fragment>
      <h1>{title}</h1>
      {content && <BlockContent blocks={content} />}
      {loading ? (
        <Skeleton />
      ) : confs.length ? (
        confs
          .sort((a: Conference, b: Conference) => {
            return Number(new Date(a.dates.startDate)) - Number(new Date(b.dates.endDate));
          })
          .map((conf: Conference) => {
            return (
              <article key={conf._id} className={css.conf}>
                <h2 className={css.title}>{conf.title}</h2>

                <div className={css.dates}>
                  <GoCalendar style={{ marginRight: 10 }} />
                  <time dateTime={format(new Date(conf.dates.startDate), "yyyy-MM-dd")}>
                    {format(new Date(conf.dates.startDate), "do MMMM yyyy")}
                  </time>
                  {conf.dates.endDate && (
                    <Fragment>
                      &nbsp;–&nbsp;
                      <time dateTime={format(new Date(conf.dates.endDate), "yyyy-MM-dd")}>
                        {format(new Date(conf.dates.endDate), "do MMMM yyyy")}
                      </time>
                    </Fragment>
                  )}
                </div>

                <p className={css.location}>
                  <GoLocation style={{ marginRight: 10 }} /> {conf.location}
                </p>

                <div className={css.links}>
                  {conf.links && conf.links.register && (
                    <Button text="Register" link={conf.links.register} />
                  )}
                  {conf.links && conf.links.submit && (
                    <Button text="Submit" link={conf.links.submit} />
                  )}
                  {conf.links && conf.links.website && (
                    <Button text="Website" link={conf.links.website} />
                  )}
                  {conf.links && conf.links.programme && (
                    <Button text="Programme" link={conf.links.programme} />
                  )}
                </div>
              </article>
            );
          })
      ) : (
        <Alert type="info" message="No conferences to show at the moment." />
      )}
    </Fragment>
  );
};

export default Conferences;
