import React from "react";
import css from "./Skeleton.module.css";

const Skeleton = () => {
  return (
    <div className={css.skeletonLoading}>
      <p className={css.loadingTitle}>&nbsp;</p>
      <p style={{ width: "100%" }} className={css.loadingPara}>
        &nbsp;
      </p>
      <p style={{ width: "80%" }} className={css.loadingPara}>
        &nbsp;
      </p>
      <p style={{ width: "100%" }} className={css.loadingPara}>
        &nbsp;
      </p>
      <p style={{ width: "80%" }} className={css.loadingPara}>
        &nbsp;
      </p>
      <p style={{ width: "90%" }} className={css.loadingPara}>
        &nbsp;
      </p>
      <p style={{ width: "100%" }} className={css.loadingPara}>
        &nbsp;
      </p>
      <p style={{ width: "50%" }} className={css.loadingPara}>
        &nbsp;
      </p>
    </div>
  );
};

export default Skeleton;
