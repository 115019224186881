import React, { useEffect, useState, Fragment } from "react";
import css from "./Sidebar.module.css";
import { sanityClient } from "../../lib";
import { MenuItem, Page } from "../../models";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../images/logo.png";
import { MdMenu } from "react-icons/md";
import { FaAngleRight } from "react-icons/fa";

const Menu = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(isHome);

  async function getMenu() {
    try {
      const menuFetch = await sanityClient.fetch(
        `*[_type=='menu'] | order(order asc) {_id, name, "icon": icon.asset->url, color, toplevelPath, pages[]->{_id, title, path}}`
      );

      setMenuItems(menuFetch);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getMenu();
  }, []);

  useEffect(() => {
    setMobileMenuOpen(isHome);
  }, [location.pathname]);

  function toggleMobileMenu() {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  interface iLoadingMenuItem {
    backgroundColor: string;
  }

  const LoadingMenuItem = ({ backgroundColor }: iLoadingMenuItem) => {
    return (
      <li className={[css.parentItem, css.loading].join(" ")}>
        <button
          className={[css.parentButton, css.loading].join(" ")}
          style={{ background: backgroundColor }}>
          &nbsp;
        </button>
      </li>
    );
  };

  return (
    <aside className={css.aside}>
      <Link to="/">
        <img className={css.logo} src={Logo} alt="Profile Productions" />
      </Link>

      <div className={css.items}>
        {/* <Link to="/current" className="registerBtn">
          Register Now &nbsp;
          <FaAngleRight />
        </Link> */}

        <button onClick={toggleMobileMenu} className={css.mobileToggleMenu}>
          <MdMenu
            style={{
              fill: "#fff",
              width: "35px",
              height: "35px",
              marginRight: "10px"
            }}
          />{" "}
          Menu
        </button>
      </div>

      <nav>
        <ul
          className={[css.menu, mobileMenuOpen ? css.mobileMenuOpen : css.mobileMenuNotOpen].join(
            " "
          )}>
          {menuItems.length > 0 ? (
            menuItems.map((item: MenuItem) => (
              <li
                key={item._id}
                className={css.parentItem}
                onClick={() => setMobileMenuOpen(false)}>
                {/* Top level menu item */}
                <button className={css.parentButton} style={{ background: item.color.hex }}>
                  <img className={css.icon} src={item.icon} alt={item.name} />
                </button>

                {/* Child menu items */}
                <ul className={css.subMenu} style={{ background: item.color.hex }}>
                  {item.pages ? (
                    item.pages.map((page: Page) => (
                      <li key={page._id} className={css.subItem}>
                        <Link className={css.subItemLink} to={page.path}>
                          {page.title}
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li className={css.subItem}>
                      <Link to={item.toplevelPath || "#"} className={css.subItemLink}>
                        {item.name}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            ))
          ) : (
            // Loading Skeleton
            <Fragment>
              <LoadingMenuItem backgroundColor="#da3f8d" />
              <LoadingMenuItem backgroundColor="#06b6e4" />
              <LoadingMenuItem backgroundColor="#0076ba" />
              <LoadingMenuItem backgroundColor="#dba708" />
              <LoadingMenuItem backgroundColor="#332a86" />
              <LoadingMenuItem backgroundColor="#983d86" />
            </Fragment>
          )}
        </ul>
      </nav>
    </aside>
  );
};

export default Menu;
