import React, { useState, useEffect, ReactNode } from "react";
import css from "./Page.module.css";
import { sanityClient } from "../../lib";
import { Page as PageModel } from "../../models";
import Alert from "../Alert";
import Skeleton from "../Skeleton";
import { Default, Contact, People, Conferences } from "./templates";

interface iPage {
  path: PageModel["path"];
  template?: string;
  children?: ReactNode;
}

const Page = ({ path, template, children }: iPage) => {
  const [pageData, setPageData] = useState<null | PageModel>(null);
  const [loading, setLoading] = useState<boolean>(true);

  async function getData() {
    try {
      const pageDataFetch = await sanityClient.fetch(
        `*[_type == 'page' && path == "${path}"][0] {title, content, backgroundColor, template, "backgroundImage": backgroundImage.asset->url}`
      );

      setPageData(pageDataFetch);

      // Add id of path to main element for css background image
      console.log(path);
      (document.querySelector(".App") as HTMLElement).id =
        path.length > 0 ? path.substring(1) : "home";

      document.title = pageDataFetch.title
        ? `Profile Productions | ${pageDataFetch.title}`
        : "Profile Productions";
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [path]); //eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <main className={css.loadingContent}>
        <Skeleton />
      </main>
    );
  }

  if (!pageData) {
    return (
      <div className="overlay">
        <Alert banner type="error" message="Connection error: could not load page data." />
      </div>
    );
  }

  interface iShell {
    children: ReactNode;
  }

  const Shell = ({ children }: iShell) => (
    <main
      id={path}
      className={css.content}
      style={{ backgroundColor: pageData.backgroundColor.hex }}>
      {children}
    </main>
  );

  // Define templates here
  switch (template || pageData.template) {
    case "empty":
      return <div className={css.spacer} />;

    case "people":
      return (
        <Shell>
          <People data={pageData} />
        </Shell>
      );

    case "contact":
      return (
        <Shell>
          <Contact data={pageData} />
        </Shell>
      );

    case "conferences":
      return (
        <Shell>
          <Conferences data={pageData} />
        </Shell>
      );

    // Hard-coded pages
    case "static":
      return (
        <main id={path} className={css.content} style={{ backgroundColor: "#e7faff" }}>
          {children}
        </main>
      );

    // If no template has been specifically defined in the CMS
    default:
      return (
        <Shell>
          <Default data={pageData} />
        </Shell>
      );
  }
};

export default Page;
