import React from "react";
import css from "./Quote.module.css";

interface iQuote {
  author: string;
  content: string;
  organisation: string;
}

const Quote = ({ author, content, organisation }: iQuote) => {
  return (
    <blockquote className={css.quote}>
      <p>
        <em>“{content}”</em>
      </p>
      <footer>
        <strong>{author}</strong>, <cite>{organisation}</cite>
      </footer>
    </blockquote>
  );
};

export default Quote;
