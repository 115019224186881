import React, { Fragment } from "react";
import BlockContent from "@sanity/block-content-to-react";
import { Page as PageModel } from "../../../../models";
import Quote from "../../../Quote";

interface iDefault {
  data: PageModel["data"];
}

export const Default = ({ data }: iDefault) => {
  const { title, content } = data;

  const serializers = {
    types: {
      quote: (props: any) => {
        const { author, content, organisation } = props.node;
        return <Quote author={author} content={content} organisation={organisation} />;
      }
    }
  };

  return (
    <Fragment>
      <h1>{title}</h1>

      {content && <BlockContent blocks={content} serializers={serializers} />}
    </Fragment>
  );
};

export default Default;
