import React from "react";
import css from "./Footer.module.css";
import { MdPhone, MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <footer className={css.footer}>
      <ul className={css.socials}>
        <li>
          <a
            href="https://www.facebook.com/profileproductions2014"
            target="_blank"
            rel="noopener noreferrer">
            <img
              alt="Facebook"
              src="https://d33wubrfki0l68.cloudfront.net/fa2b3505728bc9dccd153b24b234e946b2946317/0ef9b/assets/layout/facebook-59060c3cab94a880cd77d7000a2cb603.png"
            />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/ProfileProducti" target="_blank" rel="noopener noreferrer">
            <img
              alt="Twitter"
              src="https://d33wubrfki0l68.cloudfront.net/120304f4b675176db331572c9ca072054fcaf82d/1107e/assets/layout/twitter-a0dd3aca6f22573c089e942e91f7873e.png"
            />
          </a>
        </li>
      </ul>
      <div className={css.contactInfo}>
        <p>
          <MdPhone style={{ marginRight: 10 }} />
          <a href="tel:+442037255840">+44 (0) 20 3725 5840</a>
        </p>
        <p>
          <MdEmail style={{ marginRight: 10 }} />
          <a href="mailto:info@profileproductions.co.uk">info@profileproductions.co.uk</a>
        </p>
      </div>
      <p className={css.policies}>
        <a href="/assets/privacy_policy.pdf">Privacy Policy</a>
      </p>
      <p className={css.copyright}>&copy; Profile Productions {new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;
