import React, { Fragment, useState, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";
import { Link, useParams, Route } from "react-router-dom";
import css from "./People.module.css";
import { Staff } from "../../../../models/Staff";
import { sanityClient } from "../../../../lib";
import Alert from "../../../Alert";
import Skeleton from "../../../Skeleton";

interface iPeople {
  data: any;
}

export const People = ({ data }: iPeople) => {
  const { title, content } = data;
  const { slug } = useParams();

  const [people, setPeople] = useState<Staff[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  async function getStaff() {
    try {
      const fetch = await sanityClient.fetch(
        slug
          ? `*[_type == "staff" && slug == "${slug}"] {name, jobTitle, bio, "profilePic": profilePic.asset->url}`
          : `*[_type == "staff"] {_createdAt, _id, name, jobTitle, bio, "profilePic": profilePic.asset->url, slug }`
      );
      setPeople(fetch);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getStaff();
  }, []);

  // Individual profile page
  if (slug) {
    return (
      <Fragment>
        <h1>{title}</h1>
        {people.length ? (
          <article className={css.content}>
            <img className={css.pic} src={people[0].profilePic} alt={people[0].name} />
            <div className={css.info}>
              <h2 className={css.personTitle}>{people[0].name}</h2>
              <h3 className={css.personJobTitle}>{people[0].jobTitle}</h3>
              <BlockContent blocks={people[0].bio} />
            </div>
          </article>
        ) : (
          <Skeleton />
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <h1>{title}</h1>
      {content && <BlockContent blocks={content} />}
      {loading ? (
        <Skeleton />
      ) : people.length ? (
        <div className={css.staffMembers}>
          {people
            .sort((a: Staff, b: Staff) => {
              return Number(new Date(a._createdAt)) - Number(new Date(b._createdAt));
            })
            .map((staff: Staff) => {
              const { _id, name, slug, profilePic } = staff;
              return (
                <Link key={_id} to={`/people/${slug}`} className={css.person}>
                  <h2 className={css.nameOverlay}>{name}</h2>
                  <img className={css.profileImage} src={profilePic} alt={name} />
                </Link>
              );
            })}
        </div>
      ) : (
        <Alert type="info" message="No staff to show at the moment." />
      )}
    </Fragment>
  );
};

export default People;
